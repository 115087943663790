import styled from 'styled-components'
import { Media } from '../theme'
import { createGlobalStyle } from 'styled-components'

export const PageStyle = createGlobalStyle`
  body {
    overflow: auto;
    height: 100vh;
    background: rgba(16, 29, 39, 1);
    .sticky-background-image{
      position: sticky;
      top: 0px;
      z-index: -1;
      margin-bottom: 520px;
      min-height: 749px;
      height: 100vh;
      margin-top: calc(-100vh - 520px);
    }
  }
`

export const Title = styled.h1`
  font-family: Source Han Sans CN, Source Han Sans SC, Lato;
  color: #f3f6f7;
  text-align: center;
  padding: 198px 0px 20px;
  font-size: 60px;
  font-weight: bold;
  span {
    margin: 0 15px;
  }
  ${Media.phone`
    font-size: 30px;
    line-height: 30px;
    padding: 99px 0 22px;
  `}
`
export const ContentBox = styled.div`
  width: 1440px;
  margin: 105px auto 115px auto;
  ${Media.phone`
    margin: 37px auto 0 auto;
    width: 345px;
  `}
`
