export const practiceJobCard = [
  {
    id: 'research',
    content: [
      {
        title: 'Java后台开发工程师',
        uties:
          '参与公司云产品开发，按照项目经理以及产品经理的要求完成后台功能开发，配合前端联调接口',
        require:
          '1.  熟悉Java语言，熟悉Spring、MyBatis等开源框架\n' +
          '2.  熟悉面向对象设计\n' +
          '3.  熟悉MySQL数据库,或其他数据库\n' +
          '4.  了解OpenStack者优先',
      },
      {
        title: 'H5前端开发工程师',
        uties: '参与公司云产品开发，按照产品需求完成功能，按照UI要求完成页面交互',
        require:
          '1.  具有较好的计算机基础\n' +
          '2.  熟悉HTML、CSS、JavaScript\n' +
          '3.  了解前端模块化，组件化开发\n' +
          '4.  了解TypeScript\n' +
          '5.  了解webRTC者优先',
      },
      {
        title: 'C++开发工程师',
        uties: '参与公司云产品开发，按照项目经理以及产品经理的要求完成产品功能',
        require:
          '1.  熟练掌握C/C++, 掌握STL标准库;\n' +
          '2.  熟悉多进程、多线程，进程间通信机制；\n' +
          '3.  熟练掌握QT，并有QT实际项目开发经验；\n' +
          '4.  熟基于QT跨平台界面（windows/Mac/Linux）开发经验优先\n' +
          '5.  熟音视频开发经验，有ffmpeg/h264/opus经验优先',
      },
    ],
  },
  {
    id: 'technology',
    content: [
      {
        title: '技术培训生',
        onlyUties: '镭速产品技术支持/售前',
      },
      {
        title: '技术培训生',
        onlyUties: '实时渲染技术支持/售前',
      },
    ],
  },
  {
    id: 'operation',
    content: [
      {
        title: '运营培训生',
        onlyUties: '实时/镭速运营；营销、文案、策划',
      },
    ],
  },
  {
    id: 'business',
    content: [
      {
        title: '销售培训生',
        onlyUties: '实时渲染销售；销售能力',
      },
      {
        title: '销售培训生',
        onlyUties: '镭速软件产品销售；销售能力',
      },
      {
        title: '初级销售',
        onlyUties: '负责云电脑云桌面客户跟进和业务洽谈',
      },
      {
        title: 'TD',
        onlyUties: '负责效果图和青椒云客户问题对接和问题处理',
      },
    ],
  },
  {
    id: 'position',
    content: [
      {
        title: '人事助理/专员',
        onlyUties:
          '负责招聘、培训相关工作（包括但不限于招聘执行/运营/部分策划工作、培训执行/部分策划工作等）',
      },
    ],
  },
]

export const societyJobCard = [
  {
    id: 'technology',
    content: [
      {
        title: '系统运维工程师',
        uties:
          '1.  负责云计算IAAS平台的运维；\n' +
          '2.  pKVM和OpenStack等云管理平台运维；\n' +
          '3.  负责基础架构设施的建设、运维与监控，故障处理。',
        require:
          '1.  大专及以上学历，3年以上大型系统运维经验优先；\n' +
          '2.  熟悉基础架构设施的规划、运维与监控；\n' +
          '3.  熟悉KVM虚拟化技术和OpenStack等云管理平台运维；\n' +
          '4.  熟悉bash、python、Shell的其中一种语言；\n' +
          '5.  高度的责任感，较强的故障分析及排除能力，善于在工作中学习，能够承受工作压力。',
      },
      {
        title: '网络工程师',
        uties:
          '1.  负责公司的IDC网络的总体规划、建设、实施和维护；\n' +
          '2.  负责应急问题处理、控制网络系统变更风险，解决网络故障；\n' +
          '3.  根据业务需求，规划设计多路由协议、多出口、多业务、冗余的大型网络的网络架构；\n' +
          '4.  参与网络运维自动化体系建设，致力于实现网络运维标准化、工具化、自动化；\n' +
          '5.  安全方案规划、设计和实施，整合各种安全产品和技术为公司提供完善的安全解决方案；\n' +
          '6.  网络安全防御与检测。',
        require:
          '1.  计算机或相关专业本科或以上学历，具有中大型企业数据中心网络架构规划、设计、实施，2年以上数据中心运维经验 ;\n' +
          '2.   具有独立分析网络问题分析能力，丰富的大型网络故障排查和解决经验，能快速处理大型网络紧急事件，疑难问题；\n' +
          '3.  精通静态路由、策略路由、IGP、BGP、VLAN、STP、NAT、ACL、SNMP等协议底层工作原理；\n' +
          '4.  熟悉各种路由器、防火墙、交换机、负载均衡等网络设备的选型、部署、维护、安全防范； \n' +
          '5.  熟悉常用网络监控如（zabbix、cacti等）搭建和应用；\n' +
          '6.  精通网络安全技术：包括端口、服务漏洞扫描、程序漏洞分析检测、权限管理、入侵和攻击分析追踪、网站渗透、病毒木马防范、网络安全审计等；\n' +
          '7.  吃苦耐劳,能够承受较强的加班压力和繁重的工作压力,并能自我驱动进行持续学习必须具备良好的沟通能力。',
      },
      {
        title: 'IDC运维工程师',
        uties:
          '1.  负责数据中心电力、制冷与空调、消防、安防和监控，机房环境等基础设施系统管理和运维，确保数据中心安全、可靠、高效运行；\n' +
          '2.  负责机房基础设施（空调、电力、温度、湿度等方面）及机器设备的日常管理和告警处理，维护巡检及故障报修，保持机房运行环境的良好状态；\n' +
          '3.  负责组织、协调和执行数据中心基础设施运维管理制度、运维流程、故障处理及应急预案等。',
        require:
          '1.  大专及以上学历，暖通、电气相关专业；\n' +
          '2.  熟悉数据中心高低压供配电、暖通空调、UPS、综合布线等\n' +
          '3.  熟悉数据中心机房的建设流程和运维流程；\n' +
          '4.  有较强的沟通协调能力，较强的文档归纳能力，故障判断及应急处理经验。',
      },
      {
        title: 'Maya中级技术支持(TD)',
        uties:
          '1.  负责平台渲染技术支持、在线解决用户渲染问题；\n' +
          '2.  需掌握经岗位培训后所必备的操作技能；\n' +
          '3.  负责用脚本语言优化处理问题和软件插件安装的方式；\n' +
          '4.  能够快速的为客户定制渲染流程，进行渲染测试，和解决渲染中的各种问题；\n' +
          '5.  配和研发改平台渲染流程；\n' +
          '6.  有良好的职业道德及敬业精神，能接受5（天）*8小时轮岗值班安排。',
        require:
          '1.  热爱CG，对CG相关技术有兴趣；\n' +
          '2.  对maya的制作流程有深入的了解，三年以上CG行业相关工作经验，熟悉Mel 、python，能够使用Mel，Python编写脚本 ，有相关流程开发经验者优先考虑；\n' +
          '3.  熟练使用arnold vray redshift renderman其中一款渲染器，对渲染农场了解者优先考虑；\n' +
          '4.  了解计算机硬件、网络、存储等相关知识，对window系统cmd脚本 和linux shell脚本了解者优先；\n' +
          '5.  对于创新及解决具有挑战性的问题充满激情，具有良好的判断及分析能力，对新技术要有钻研和学习精神；\n' +
          '6.  能够准确理解客户需求，并制定出合理的解决方案，善于沟通，具备良好服务意识；\n' +
          '7.  有一定的英语读写能力；\n' +
          '8.  本工作对艺术美感无要求。',
      },
      {
        title: 'DBA工程师 ',
        uties:
          '1.  负责公司内部数据库的日常维护，包括数据库的状态检查、数据备份、数据恢复、性能优化，故障处理等，确保数据库的安全和可靠；\n' +
          '2.  负责公司内部数据库的架构设计，服务配置；\n' +
          '3.  负责对研发上线的SQL语句进行审核和优化；\n' +
          '4.  负责mysql开发规范的制定、并对开发培训、监控、优化；\n' +
          '5.  熟悉linux操作系统和常用的性能分析等运维工具。',
        require:
          '1.  3年以上mysql相关工作经验，熟悉CentOS操作系统，和常用的shell命令；\n' +
          '2.  至少会shell、python中的一种编程语言，能熟练的编写SQL脚本；\n' +
          '3.  熟悉常用的数据库，包括mysql、mongodb、redis、ES，精通mysql的维护和管理；\n' +
          '4.  熟悉常用的数据库架构，能快速定位mysql的性能和瓶颈问题，并提出合理的改善建议；\n' +
          '5.  熟悉常用的数据备份和数据恢复的工具；\n' +
          '6.  熟悉zabbix监控系统，能熟练的配置和使用监控；\n' +
          '7.  为人温和、上进心强、责任心强、有良好的服务意识。',
      },
    ],
  },
  {
    id: 'research',
    content: [
      {
        title: 'Unreal程序开发工程师',
        uties:
          '1.  负责实时渲染项目的开发；\n' +
          '2.  实现UE4引擎逻辑功能代码的扩展；\n' +
          '3.  技术文档整理；\n' +
          '4.  完成产品的整体开发；\n' +
          '5.  内部赋能培训。',
        require:
          '1.  熟悉UE4开发框架和流程、熟练C++蓝图混合编程；\n' +
          '2.  有较强的自主学习能力，有独立开发能力；\n' +
          '3.  逻辑清晰，编程基础好，代码经验丰富；\n' +
          '4.  2年以上游戏开发经验，有完整游戏项目经验优先。',
      },
      {
        title: 'C++开发工程师 ',
        uties:
          '1.  参与公司产品的设计、开发与维护工作；\n' +
          '2.  搭建系统开发环境，完成系统框架和核心代码的实现；\n' +
          '3.  系统开发测试、部署和集成，解决开发过程中的技术问题。',
        require:
          '1.  本科及以上学历，计算机、通讯、自控及相关专业，2年以上软件产品设计开发经验；\n' +
          '2.  精通C/C++的基本语法和原理，精通至少一种C/C++IDE；\n' +
          '3.  熟悉Windows环境开发技术，如：进程间通讯、多线程、动态链接库或COM技术；\n' +
          '4.  能够使用C++语言及面向对象思想的技能，掌握一种C++ GUI应用程序开发框架，如QT等；\n' +
          '5.  具备数据库技术、计算机图形知识、串口通信、网络通信、多线程技术的开发能力；\n' +
          '6.  具有较强的分析问题和解决问题能力，能够独立定位并解决测试发现的BUG；\n' +
          '7.  有过复杂模块成功重构经验；\n' +
          '8.  具有良好的沟通协作能力，有带领团队经验者优先。',
      },
      {
        title: 'C++工程师（OpenVR）',
        uties:
          '1.  参与CloudXR平台的设计与开发；\n' +
          '2.  参与客户端SDK的设计与开发；\n' +
          '3.  持续改进相关产品的质量、性能和用户体验。',
        require:
          '1.  精通C/C++语言，熟悉常用的数据结构与算法，基础扎实，代码编写规范；\n' +
          '2.  熟练使用STL，熟悉网络开发，熟悉常用设计模式；\n' +
          '3.  具备较强的学习能力，具有团队合作精神，能够在压力下独立工作并按时完成；\n' +
          '4.  具有OpenVR API使用经验者优先；\n' +
          '5.  具备DirectX、OpenGL渲染相关经验者优先；\n' +
          '6.  有基于Oculus，HTC等SDK VR相关的开发经验者优先。',
      },
    ],
  },
  {
    id: 'business',
    content: [
      {
        title: '私有云销售主管',
        uties:
          '1.  公司云计算相关产品（政企云、私有云、混合云等）和解决方案在所属区域、行业的销售工作，完成个人业绩指标；\n' +
          '2.  市场商机、销售线索、项目信息的采集、筛选、反馈、跟进；\n' +
          '3.  针对客户制定云计算业务营销方案，组织重点客户攻关，提供建议书及报价，促成客户签单；\n' +
          '4.  项目进展中，全程做好商务支持、客户技术协调等工作，配合其他部门顺利完成客户验收，保证回款；\n' +
          '5.  客户关系建设、重点客户维系、高度关注客户满意度，建立良好的客户交流通道，挖掘和引导客户需求；\n' +
          '6.  协助公司市场部在指定区域或者行业内实施市场推广计划，协调行业合作伙伴及业务资源。',
        require:
          '1.  熟悉云计算、大数据等技术发展趋势，熟悉主流云计算、虚拟化产品的功能特点；\n' +
          '2.  至少5年以上IT行业，如服务器、存储、网络、虚拟化、系统集成、解决方案等的销售工作经验，成功项目金额在百万级以上；\n' +
          '3.  可独立地开发目标客户群（政府、部队、大中型企业，高校，公用事业单位等），不断收集挖掘潜在客户，具有现成客户资源或相关行业人脉关系优先；\n' +
          '4.  有新客户开拓及顾问式解决方案销售经验，娴熟的大客户销售技巧，商务谈判能力；\n' +
          '5.  表达能力强，能针对客户进行有效的现场方案演示讲解，引导和激发客户；\n' +
          '6.  自信乐观、亲和力强，工作有冲劲，热情饱满，精力充沛；\n' +
          '7.  敢于承担责任，有较强的抗压能力，极强的进取心和执行能力。',
      },
      {
        title: '商务拓展经理（青椒云）',
        uties:
          '1.  负责公司云桌面产品的市场渠道开拓和销售工作，执行并完成公司产品年度销售计划和目标；\n' +
          '2.  积极拓展各类客户及合作伙伴，维护客户关系，跟进客户需求，把控项目节奏推动签约及回款；\n' +
          '3.  收集市场信息，动态把握市场情况，定期向公司提供市场分析及预测报告；\n' +
          '4.  定期反馈市场用户需求和产品情况，协助产品线做好产品优化和竞争力提升。',
        require:
          '1.  本科及以上学历，计算机或网络通信类专业，有虚拟化、云桌面经验或行业资源者优先考虑；\n' +
          '2.  了解云桌面、服务器、存储、网络安全、网络管理等基础系统和知识；\n' +
          '3.  能迅速理解和归纳客户需求，具备一定的市场分析及判断能力，有较强的商业意识和谈判技巧，能独立通过网络、电话或有效渠道开扩新客户；\n' +
          '4.  具备良好的逻辑思维及沟通能力，有较强的进取心和自我学习能力；\n' +
          '5.  具备很强的事业心和较好的团队合作精神。',
      },
      {
        title: '销售总监（传输软件）',
        uties:
          '1.  负责公司镭速传输软件国内市场的拓展，达成业务指标；\n' +
          '2.  寻找商机，开拓客户，挖掘和引导客户需求，并积极推进项目进度，达成合作；\n' +
          '3.  充分利用公司内、外部资源，积累目标客户和生态合作伙伴，建立良好客情关系，做好渠道建设和维护，促进渠道和合作伙伴推广传输产品，并推动业务发展，签约项目；\n' +
          '4.  组织协调技术同事，解决客户售前、售中、售后等技术问题。',
        require:
          '1.  五年以上软件销售经验，能承受销售工作压力和适应出差；\n' +
          '2.  良好的业务拓展能力、客户开发能力、渠道建设能力，有行业客户和渠道资源优先；\n' +
          '3.  有政府和事业单位招投标项目优先；\n' +
          '4.  有销售团队管理经验优先；\n' +
          '5.  较强的人际交往能力和较高的谈判技巧；\n' +
          '6.  较强的市场洞察力、模式创新能力、资源整合能力；\n' +
          '7.  大专及以上学历，计算机/IT专业优先。',
      },
      {
        title: '销售顾问',
        uties:
          '1.  主动开发新客户，整理客户材料，建立客户档案信息；\n' +
          '2.  完成客户洽谈、签订合同、跟单和后期维护工作；\n' +
          '3.  完成公司下达的月销售任务；\n' +
          '4.  维护好客户与公司的关系；\n' +
          '5.  能持续学习，不断进取，提升自我。',
        require:
          '1.  年龄20-35岁；\n' +
          '2.  大专及以上学历，有3年以上销售工作经验；\n' +
          '3.  有较强的学习、沟通能力，善于交际；\n' +
          '4.  有良好的团队合作意识和承压能力；\n' +
          '5.  能吃苦耐劳，保持良好的工作积极性；\n' +
          '6.  有在CG，动画、广告行业经验优先；有熟悉三维制作软件和领域工作经验的优先。',
      },
      {
        title: '渲染英文顾问 ',
        uties:
          '1.  负责日常客户咨询工作，例如接听客户来电、基本客户关系维护，日常平台skype沟通，以及回复咨询邮件等；\n' +
          '2.  负责渲染平台技术在线咨询应答及电话回访工作；\n' +
          '3.  具备营销意识及一定的谈判技巧，在与客户沟通过程中促成合作；\n' +
          '4.  建立并整理意向客户档案，配合市场人员做好营销工作；\n' +
          '5.  能适应7（天）*24小时轮岗值班安排。',
        require:
          '1.  大专以上学历，2年以上同职位工作经验；\n' +
          '2.  英语听说读写熟练，英语可作工作语言；\n' +
          '3.  具有较强的工作主动性，工作态度积极；较强责任心与服务意识；\n' +
          '4.  性格开朗、能够耐心的解决客户提出的问题；\n' +
          '5.  具有良好的逻辑思维能力、沟通能力；具有国际化服务的意识和思维；\n' +
          '6.  需要有良好的沟通协调能力,能迅速理解好每个咨询客户的需求信息，对客户反馈的问题做好跟踪管理，同时配合各部门一起做好售后服务工作；\n' +
          '7.  有团队合作精神,能与公司各相关部门同事沟通协作，共同配合使项目顺利进行。',
      },
    ],
  },
  {
    id: 'position',
    content: [
      {
        title: '人力资源专员（招培方向） ',
        uties:
          '1.  理解公司业务发展战略，根据公司人力资源规划，承接人才的引进及招聘达成率；\n' +
          '2.  主导面试流程，并通过HR面试对候选人进行综合评估，筛选出符合岗位要求及公司文化价值观的人选；\n' +
          '3.  负责招聘相关的人力资源项目，包括但不限于招聘运营、校招、线下社招、人才测评等；\n' +
          '4.  主导或参与新员工培训、关键岗位、管理团队赋能项目；\n' +
          '5.  负责技术部门HRBP支持工作。',
        require:
          '1.  本科及以上学历，至少1年以上甲方公司工作经验，熟悉招聘全流程；\n' +
          '2.   有云计算或互联网行业背景或资源者优先考虑，有招聘运营、校招经验者优先考虑；\n' +
          '3.   擅长中高端人才的搜寻，熟悉并掌握各类面试方法和技巧；\n' +
          '4.  沟通能力优秀，抗压能力强，强烈的目标感和内驱力、结果导向，优秀的沟通协调能力和人际敏感度。',
      },
    ],
  },
  {
    id: 'design',
    content: [
      {
        title: '交互设计师 ',
        uties:
          '1.  根据产品需求，设计公司相关Web、App、PC等产品的交互方案；\n' +
          '2.  推进体验设计项目，对产品整体用户体验负责；\n' +
          '3.  深入分析现有产品的界面交互优缺点，提出有效解决方案并协助优化；\n' +
          '4.  配合产品、协同视觉设计师、开发，保障设计高品质上线；\n' +
          '5.  输出产品相关的交互设计文档、设计规范、标准等，保证产品体验以及跨产品体验统一。',
        require:
          '1.  本科及以上学历，从事交互设计行业工作2年以上；\n' +
          '2.  熟悉互联网、移动互联网产品开发流程；\n' +
          '3.  精通Web端，App端以及PC端用户体验设计流程；\n' +
          '4.  良好的需求把握和逻辑分析能力，对设计问题有敏锐的洞察力；\n' +
          '5.  较强的沟通能力、思维逻辑能力，能主动工作，能充分理解产品需求、用户场景；\n' +
          '6.  熟练使用 Axrue Rp等相关软件，掌握界面原型、流程、动效的交互设计方法。',
      },
    ],
  },
]
