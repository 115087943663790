import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../components/layout'
import RecruitmentTab from '../components/recruitment/tab'
import JoinFotter from '../components/join-footer'
import { TabType } from '../components/recruitment/type'
import Seo from '../components/seo'
import { Title, ContentBox, PageStyle } from '../style/pages/recruitment.atom'

const RecruitmentPage = () => {
  return (
    <Layout>
      <PageStyle />
      <Seo
        title='社会招聘 - 瑞云科技招聘'
        description='瑞云科技社会招聘-深圳市瑞云科技股份有限公司是一家专注为视觉行业提供垂直云计算服务的公司,随着5G的普及,瑞云科技作为“新型基础设施”,致力为视觉行业提供一系列IaaS、PaaS及SaaS云服务.加入我们,渲染无限未来！'
        keywords='瑞云社会招聘,瑞云科技招聘,Rayvision 招聘'
      />
      <StaticImage
        className='sticky-background-image'
        layout='fullWidth'
        alt='case-background'
        src='../images/recruitment/banner_2.jpg'
      />
      <Title>
        <span>J O I N</span> <span>U S</span>
      </Title>
      <Title style={{ padding: '0px' }}>加入我们 渲染无限未来</Title>
      <ContentBox>
        <RecruitmentTab style={{ display: 'flex' }} type={TabType.society} />
      </ContentBox>
      <JoinFotter />
    </Layout>
  )
}

export default RecruitmentPage
