import styled from 'styled-components'
import { color, spacing, Media, fontSize, transition } from '../../style/theme'

export const ItemBox = styled.div`
  padding: 30px 40px;
  column-count: 2;
  border-radius: 0 10px 10px 10px;
  flex-flow: row;
  flex-wrap: wrap;
  min-height: 354px;
  &.dark {
    box-shadow: 0px 10px 16px 4px rgba(51, 51, 51, 0.35);
    background: rgba(34, 34, 34, 0.8);
  }
  &.light {
    background: #ffffff;
  }
  ${Media.phone`
    column-count: auto;
    padding: 15px 20px;
    flex-flow: column;
  `}
`
export const Tab = styled.div`
  display: flex;
  ${Media.phone`
    max-width: 325px;
  `}
`

export const ListTitle = styled.div`
  border-radius: 10px 10px 0px 0px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
  line-height: 60px;
  margin-right: ${spacing.base};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.small};
  cursor: pointer;
  transition: ${transition([
    'height',
    'margin-top',
    'font-size',
    'font-weight',
    'color',
    'background',
  ])};
  &.active-title {
    margin-top: 0px;
    height: 60px;
    font-size: ${fontSize.large};
    font-weight: bold;
  }
  &.dark {
    opacity: 0.8;
    color: rgba(190, 195, 197, 1);
    background: #101d27;
    &.active-title {
      background: rgba(34, 34, 34, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  &.light {
    background: ${color.primaryLight};
    color: #ffffff;
    &.active-title {
      color: #333333;
      background: #ffffff;
    }
  }
  ${Media.phone`
    line-height: 30px;
    font-size: ${fontSize.tiny};
    min-width: 60px;
    height: 25px;
    margin-right: 5px;
    margin-top: 5px;
    &.active-title{
      height: 30px;
      font-size: ${fontSize.mini};
    }
    :last-child{
      display: none;
    }
  `}
`
export const Card = styled.div`
  page-break-inside: avoid;
  break-inside: avoid-column;
  padding: 28px 43px 15px 43px;
  display: flex;
  flex-flow: column;
  @-moz-document url-prefix() {
    overflow: hidden;
  }
  .delivery {
    width: 160px;
    height: 40px;
    background: #02a6bc;
    border-radius: 20px;
    font-size: ${fontSize.medium};
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    border: none;
    margin-top: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }
  &.dark :hover {
    background: #333333;
  }
  &.light :hover {
    box-shadow: 0px 10px 16px 4px rgba(204, 204, 204, 0.35);
  }
  :hover {
    border-radius: 10px;
    transition: ${transition(['background-color', 'box-shadow'])};
    .delivery {
      opacity: 1;
      transition: ${transition(['opacity'])};
    }
  }
  ${Media.phone`
    width: 100%;
    padding: 15px 25px 15px 15px;
    .delivery{
      width: 120px;
      height: 30px;
      font-size: 13px;
      margin-top: 16px;
    }
  `}
`

export const CardTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${color.primaryDark};
  line-height: 60px;
  ${Media.phone`
    font-size: 12px;
    line-height: 30px;
  `}
`

export const CardSubTitle = styled.strong`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  &.dark {
    color: rgba(255, 255, 255, 1);
  }
  &.light {
    color: rgba(51, 51, 51, 1);
  }
  ${Media.phone`
    font-size: 10px;
    line-height: 15px;
  `}
`
export const CardContent = styled.p`
  line-height: 24px;
  font-size: 14px;
  white-space: pre-wrap;
  &.dark {
    color: rgba(255, 255, 255, 1);
  }
  &.light {
    color: rgba(119, 119, 119, 1);
  }
  ${Media.phone`
    font-size: 10px;
    line-height: 15px;
  `}
`

export const MoreButton = styled.a`
  width: 160px;
  height: 40px;
  margin-right: 40px;
  background: #02a6bc;
  border-radius: 20px;
  font-size: ${fontSize.medium};
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    box-shadow: 0px 10px 16px 4px rgb(2 166 188 / 35%);
  }
`
export const MoreBox = styled.div`
  display: flex;
  padding: 80px 0 100px 40px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0px 10px 16px 4px rgba(51, 51, 51, 0.35);
  background: rgba(34, 34, 34, 0.8);
  ${Media.phone`
    column-count: auto;
    padding: 15px 20px;
    flex-flow: column;
  `}
`
