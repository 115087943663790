import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'

import {
  ItemBox,
  ListTitle,
  Tab,
  Card,
  CardSubTitle,
  CardContent,
  CardTitle,
  MoreButton,
  MoreBox,
} from './tab.atom.js'
import { TabType } from './type.js'
import { practiceJobCard, societyJobCard } from './data'

const practiceList = [
  { name: '研发类', id: 'research' },
  { name: '技术类', id: 'technology' },
  { name: '运营类', id: 'operation' },
  { name: '业务类', id: 'business' },
  // { name: '职能类', id: 'position' },
]

const societyList = [
  { name: '研发类', id: 'research' },
  { name: '技术类', id: 'technology' },
  { name: '业务类', id: 'business' },
  // { name: '职能类', id: 'position' },
  // { name: '设计类', id: 'design' },
  { name: '查看更多', id: 'more' },
]

function CardList(id, type) {
  const theme = type === TabType.society ? 'dark' : 'light'
  const jobCard = type === TabType.practice ? practiceJobCard : societyJobCard
  const card = jobCard.find((j) => j.id === id)
  if (card === undefined) return
  return (
    <>
      {card.content.map((c, index) => {
        return (
          <Card className={theme} key={index}>
            <CardTitle>{c.title}</CardTitle>
            {c.onlyUties ? (
              <CardSubTitle className={theme}>{c.onlyUties}</CardSubTitle>
            ) : (
              <>
                <CardSubTitle className={theme}>岗位职责：</CardSubTitle>
                <CardContent className={theme}>{c.uties}</CardContent>
                <CardSubTitle style={{ marginTop: '20px' }} className={theme}>
                  技术要求：
                </CardSubTitle>
                <CardContent className={theme}>{c.require}</CardContent>
              </>
            )}
            <a className='delivery' href='mailto:hr@rayvision.com' title='hr@rayvision.com'>
              投递简历
            </a>
          </Card>
        )
      })}
    </>
  )
}

const RecruitmentTab = (props) => {
  const location = useLocation()
  const isSociety = props.type === TabType.society
  const theme = isSociety ? 'dark' : 'light'
  const jobList = props.type === TabType.practice ? practiceList : societyList
  const [selectJob, setActiveJob] = useState(jobList[0].id)

  useEffect(() => {
    const href = location.hash.substring(1)
    if (href !== '' && societyList.filter((s) => s.id === href).length > 0) {
      setActiveJob(location.hash.substring(1))
    }
  }, [location])
  return (
    <div>
      <Tab style={{ display: 'flex' }}>
        {jobList.map((item) => {
          return (
            <ListTitle
              onMouseEnter={() => {
                setActiveJob(item.id)
              }}
              className={`${selectJob === item.id ? 'active-title' : ''} ${theme}`}
              key={item.id}
            >
              {item.name}
            </ListTitle>
          )
        })}
      </Tab>
      {isSociety && selectJob === societyList[societyList.length - 1].id ? (
        <MoreBox>
          <MoreButton
            href='http://jobs.51job.com/all/co2531190.html'
            target='_blank'
            rel='nofollow'
          >
            前程无忧网
          </MoreButton>
          <MoreButton
            href='http://company.zhaopin.com/_CC382743818.html'
            target='_blank'
            rel='nofollow'
          >
            智联招聘网
          </MoreButton>
        </MoreBox>
      ) : (
        <ItemBox id='active' className={theme}>
          {CardList(selectJob, props.type)}
        </ItemBox>
      )}
    </div>
  )
}

export default RecruitmentTab
